'use client'

import { Divider, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { AuthView } from '../../constants'

const BusinessRegistrationLink = () => {
  const { authView } = useAuthenticationContext()
  const isLoginView = authView === AuthView.SelectTypeLogin
  const { businessAccountInformationalLinksVisible } = useSystemConfiguration() || {}

  const translate = useTranslate()

  if (!businessAccountInformationalLinksVisible || isLoginView) return null

  return (
    <>
      <Divider />
      <div className="u-ui-padding-horizontal-large u-ui-padding-vertical-x-large">
        <Text
          type={Text.Type.Caption}
          alignment={Text.Alignment.Center}
          text={translate('auth.select_type.register.are_you_a_business')}
          width={Text.Width.Parent}
          html
        />
      </div>
    </>
  )
}

export default BusinessRegistrationLink
