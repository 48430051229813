'use client'

import { useEffect } from 'react'
import { kebabCase } from 'lodash'

import ErrorState from 'components/ErrorState'
import { isValueInObject } from 'libs/utils/object'
import PasswordVerification from 'pages/PasswordVerificationLogin'
import { LoginTwoFactorVerification } from 'pages/Verifications'

import { ExternalRegisterData } from '../types'
import { AuthExternalRegisterView, AuthView } from '../constants'
import SelectType from '../SelectType'
import EmailLogin from '../EmailLogin'
import EmailRegister from '../EmailRegister'
import ResetPassword from '../ResetPassword'
import FacebookRegister from '../FacebookRegister'
import GoogleRegister from '../GoogleRegister'
import AppleRegister from '../AppleRegister'
import useAuthTracking from '../hooks/useAuthTracking/useAuthTracking'
import useAuthenticationContext from '../hooks/useAuthenticationContext'

const componentByAuthView: Record<AuthView, () => JSX.Element | null> = {
  [AuthView.SelectTypeRegister]: SelectType,
  [AuthView.SelectTypeLogin]: SelectType,
  [AuthView.EmailLogin]: EmailLogin,
  [AuthView.EmailRegister]: EmailRegister,
  [AuthView.ResetPassword]: ResetPassword,
  [AuthView.TwoFactorLogin]: LoginTwoFactorVerification,
}

const componentByAuthExternalRegisterView: Record<
  AuthExternalRegisterView,
  (args: ExternalRegisterData) => JSX.Element
> = {
  [AuthExternalRegisterView.FacebookRegister]: FacebookRegister,
  [AuthExternalRegisterView.GoogleRegister]: GoogleRegister,
  [AuthExternalRegisterView.AppleRegister]: AppleRegister,
  [AuthExternalRegisterView.PasswordVerification]: PasswordVerification,
}

const Auth = () => {
  const { authView, externalRegisterData } = useAuthenticationContext()
  const { trackViewScreen } = useAuthTracking()

  useEffect(() => {
    trackViewScreen()
  }, [trackViewScreen])

  const renderViewWrapper = (children: JSX.Element) => (
    <span data-testid={`${kebabCase(authView)}-view`}>{children}</span>
  )

  if (isValueInObject(authView, AuthView)) {
    const Component = componentByAuthView[authView]

    return renderViewWrapper(<Component />)
  }

  if (!externalRegisterData) return <ErrorState />

  const Component = componentByAuthExternalRegisterView[authView]

  return renderViewWrapper(<Component {...externalRegisterData} />)
}

export default Auth
