'use client'

import { Spacer, Text } from '@vinted/web-ui'

import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'

import { REGISTER_URL } from 'constants/routes'
import { onA11yKeyDown } from 'data/utils/a11y'
import { getOldHostRedirectUrl } from 'libs/utils/url'
import { navigateToPage } from 'libs/utils/window'

import useAuthTracking from 'components/Auth/hooks/useAuthTracking'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { AuthView } from '../../constants'

const SelectTypeFooter = () => {
  const { authView, setAuthView } = useAuthenticationContext()
  const isLoginView = authView === AuthView.SelectTypeLogin

  const selectType = isLoginView ? 'login' : 'register'
  const translate = useTranslate(`auth.select_type.${selectType}`)
  const { host } = useLocation()
  const { trackClickEvent } = useAuthTracking()

  function handleSwitchContext() {
    if (isLoginView) {
      setAuthView(AuthView.SelectTypeRegister)
      trackClickEvent({ target: ClickableElement.SwitchToRegistration })

      return
    }

    setAuthView(AuthView.SelectTypeLogin)
    trackClickEvent({ target: ClickableElement.SwitchToLogin })
  }

  function proceedToEmailLogin() {
    setAuthView(AuthView.EmailLogin)
    trackClickEvent({ target: ClickableElement.SwitchToEmailLogin })
  }

  function proceedToEmailRegister() {
    const hostRedirectUrl = getOldHostRedirectUrl(host)
    if (hostRedirectUrl) {
      navigateToPage(`${hostRedirectUrl}${REGISTER_URL}`)

      return
    }

    setAuthView(AuthView.EmailRegister)
    trackClickEvent({ target: ClickableElement.SwitchToEmailRegistration })
  }

  function handleProceedLinkClick() {
    if (isLoginView) {
      proceedToEmailLogin()
    } else {
      proceedToEmailRegister()
    }
  }

  return (
    <>
      <Text width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.proceed.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          onClick={handleProceedLinkClick}
          data-testid={`auth-select-type--${selectType}-email`}
        >
          <Text text={translate('actions.proceed.link')} theme="primary" />
        </span>
      </Text>
      <Spacer />
      <Text width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.switch.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onClick={handleSwitchContext}
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          data-testid={`auth-select-type--${selectType}-switch`}
        >
          <Text text={translate('actions.switch.link')} theme="primary" />
        </span>
      </Text>
    </>
  )
}

export default SelectTypeFooter
